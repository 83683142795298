<template>
    <div class="layout-config h-100 p-10">
        <Row type="flex" :gutter="10">
            <Col span="4">
                <Input v-model="param.pointName" placeholder="请输入研学点" clearable />
            </Col>
            <Col span="4">
                <Button type="primary" @click="getData()">查询</Button>
                <Button type="success" class="m-l-5" @click="showModal" v-if="isAuth">新增</Button>
            </Col>
        </Row>
        <Table border :columns="cols" :data="table.data" ref="table" class="m-t-10" />
        <Page :page="param.current" @on-change="getData" :page-size="10" :total="table.total" show-total class="text-r m-t-10" />
        <Modal v-model="modal.show" :title="`${modal.data.researchPointId ? '编辑' : '新增'}研学点`" width="1000" :loading="modal.loading" @on-ok="saveData">
            <Form ref="form" :model="modal.data" :rules="modal.rules" :label-width="100" label-colon>
                <Row :gutter="20">
                    <Col span="10">
                        <FormItem label="研学点名称" prop="pointName">
                            <Input v-model="modal.data.pointName" placeholder="请输入研学点名称" clearable />
                        </FormItem>
                        <FormItem label="所属学科">
                            <SelectDict v-model="modal.data.subjects" :dict="dict.subject" multiple placeholder="请选择研学点所属学科" />
                        </FormItem>
                        <FormItem label="所属机构">
                            <Input v-model="modal.data.pointOrg.base" placeholder="研学点所属机构（自动获取）" readonly />
                        </FormItem>
                        <!-- <FormItem label="所属活动">
                            <Input v-model="modal.data.pointActivity" placeholder="研学点所属活动（自动获取）" readonly />
                        </FormItem> -->
                        <!-- <FormItem label="所属研学路线" prop="pointLines">
                            <Select v-model="modal.data.pointLines" placeholder="所属研学路线" clearable>
                                <Option :value="1">研学线路1</Option>
                            </Select>
                        </FormItem> -->
                        <FormItem label="研学点描述" prop="pointInfo">
                            <Input v-model="modal.data.pointInfo" type="textarea" placeholder="请输入研学点描述" clearable />
                        </FormItem>
                        <FormItem label="采集人" prop="pointPeople">
                            <Input v-model="modal.data.pointPeople" placeholder="请输入研学点采集人" clearable />
                        </FormItem>
                        <!-- <FormItem label="所属政区" prop="pointOrg.division">
                            <Input v-model="modal.data.pointOrg.division" placeholder="请输入所属政区" clearable />
                        </FormItem> -->
                        <FormItem label="所属学段" prop="applicablePhases">
                            <SelectDict v-model="modal.data.applicablePhases" :dict="dict.applicablePhases" multiple checkbox placeholder="所属学段" clearable />
                        </FormItem>
                        <FormItem label="适合季节" prop="pointSeason">
                            <SelectDict v-model="modal.data.pointSeason" :dict="dict.season" placeholder="请选择季节" clearable />
                        </FormItem>
                        <FormItem label="是否公开" prop="published">
                            <RadioGroup v-model="modal.data.published">
                                <Radio :label="1">是</Radio>
                                <Radio :label="0">否</Radio>
                            </RadioGroup>
                        </FormItem>
                        <FormItem label="经度">
                            <Input v-model="modal.data.pointCoord.lon" placeholder="请输入经度(点击地图自动获取)" readonly />
                        </FormItem>
                        <FormItem label="研学点全景图">
                            <!-- <GeoFile v-model="modal.data.point360" text="选择图片" upload-txt="上传图片" /> -->
                            <SelectPanorama v-model="modal.data.point360"  placeholder="请选择研学点全景图" />
                        </FormItem>
                        <FormItem label="所属研学课程">
                            <GeoFile v-model="modal.data.pointCourse" text="研学课程" upload-txt="上传课程" :course="true" :param="{ folderId: 2164, userId: $store.state.user.userId }" />
                        </FormItem>
                        <FormItem label="研学点图片">
                            <GeoFile v-model="modal.data.pointImg" text="选择图片" upload-txt="上传图片" :param="{ folderId: 2166, userId: $store.state.user.userId }" />
                        </FormItem>
                    </Col>
                    <Col span="14">
                        <FormItem label="获取位置">
                            <Map :data="modal.data" :height="400" v-if="modal.show" />
                        </FormItem>
                        <FormItem label="研学点位置" prop="pointAdd">
                            <Input v-model="modal.data.pointAdd" placeholder="请输入研学点位置(点击地图自动获取)" clearable />
                        </FormItem>
                        <FormItem label="纬度">
                            <Input v-model="modal.data.pointCoord.lat" placeholder="请输入纬度(点击地图自动获取)" readonly />
                        </FormItem>
                        <FormItem label="高度">
                            <Input v-model="modal.data.pointCoord.ele" placeholder="请输入高度" />
                        </FormItem>
                        <FormItem label="研学点视频">
                            <GeoFile v-model="modal.data.pointVideo" text="选择视频" upload-txt="上传视频" :param="{ folderId: 2167, userId: $store.state.user.userId }" />
                        </FormItem>
                    
                    </Col>
                </Row>
            </Form>
        </Modal>
        <Modal v-model="view.show" title="查看研学点" width="1000" footer-hide>
            <Row :gutter="10">
                <Col span="12">
                    <Vgroup :label-width="100">
                        <Vcell label="研学点名称：" :value="view.data.pointName" />
                        <Vcell label="研学点位置：" :value="view.data.pointAdd && view.data.pointAdd.join(',')" />
                        <Vcell label="研学点描述：" :value="view.data.pointInfo" />
                        <Vcell label="采集人：" :value="view.data.pointPeople" />
                        <!-- <Vcell label="所属政区：" :value="view.data.pointOrg.division" /> -->
                        <Vcell label="所属学段：">
                            <Tag v-for="(item, index) in view.data.applicablePhases" :key="index">{{ item }}</Tag>
                        </Vcell>
                        <Vcell label="适合季节：" :value="view.data.pointSeason" />
                        <Vcell label="所属学科：">
                            <Tag v-for="(item, index) in view.data.subjects" :key="index">{{ item }}</Tag>
                        </Vcell>
                        <Vcell label="所属机构：" :value="view.data.pointOrg.base" />
                        <Vcell label="经度：" :value="view.data.pointCoord.lon" />
                        <Vcell label="纬度：" :value="view.data.pointCoord.lat" />
                        <Vcell label="高度：" :value="view.data.pointCoord.ele" />
                        <Vcell label="所属研学课程：">
                            <Tag v-for="(item, index) in view.data.pointCourse" :key="index">
                                <span class="c-h" @click="openCourse(item)">{{ item }}</span>
                            </Tag>
                        </Vcell>
                        <Vcell label="研学点图片：">
                            <PhotoView :value="view.data.pointImg" />
                        </Vcell>
                        <Vcell label="研学路线视频：">
                            <VideoView :value="view.data.pointVideo" />
                        </Vcell>
                        <Vcell label="研学点全景图：">
                            {{ panorama.name }}
                        </Vcell>
                    </Vgroup>
                </Col>
                <Col span="12">
                    <Map :data="view.data" :height="500" :setAddress="false" v-if="view.show" />
                </Col>
            </Row>
        </Modal>
        <CourseView :modal="course" />
    </div>
  </template>
  
  <script>
  import { util } from '@/libs'
  import { dict } from '@/config'
  import { getPoint, getPointById, addPoint, updatePoint, deletePoint } from '@/libs/api/point'
  
import { getPanoramaById } from "@/libs/api/panorama";
import { mapState } from 'vuex';
  import CourseView from '@/views/dataV/index/courseView.vue'
  export default {
    components: { CourseView },
    data() {
        const _this = this;
        return {
            isAuth: null,
            dict,
            param: {
                pointName: '',
                current: 1,
                size: 10,
            },
            uploadData: {
                folderId: 2173,
                userId: this.$store.state.user.userId
            },
            table: {},
            panorama: {},
            modal: {
                show: false,
                loading: false,
                data: {
                    pointCoord: { ele: 500 },
                    pointOrg: {},
                },
                rules: {
                    pointName: { required: true, message: "请输入研学点名称" }
                }
            },
            view: {
                show: false,
                data: {
                    pointCoord: {},
                    pointOrg: {},
                }
            },
            course: {
                show: false,
                data: {}
            },
            cols: [
                { title: "序号", type: "index", key: "index", width: 70, align: "center" },
                { title: "研学点名称", key: "pointName" },
                { title: "研学点位置", key: "pointAdd", render(h, w) { return h("span", w.row.pointAdd && w.row.pointAdd.join(",")); } },
                { title: "研学点描述", key: "pointInfo", render(h, w) {
                    return h('Tooltip', {
                        class: 'w-100',
                        props: {
                            content: w.row.pointInfo,
                            trigger: 'hover',
                            size: 'small',
                            transfer: true,
                            placement: 'bottom-start',
                            maxWidth: 400
                        }
                    }, [h('div', { class: 'ivu-table-cell-ellipsis',  }, w.row.pointInfo)])}
                },
                { title: "操作", width: 200,
                    render(h, w) {
                        const btns = []
                        btns.push(h("Button", {
                        props: { type: "info", size: "small" },
                            on: {
                                click() {
                                    getPointById({ id: w.row.researchPointId }).then(res => {
                                        Object.assign(_this.view, { show: true, data: res.data });
                                        _this.getPanorama(res.data.point360)
                                    });
                                    // Object.assign(_this.view, { show: true, data: util.deepcopy(w.row) });
                                }
                            }
                        }, "查看"))
                        // w.row.ownerId == _this.userId 本人编辑及删除
                        // roleCode 角色编码  'admin', 'teach', 'yxpt'(管理员，机构管理员，教研平台用户，研学平台用户教师用户)
                        if(_this.isAuth) {
                            btns.push(
                                h("Button", {
                                    props: { type: "success", size: "small" },
                                    on: {
                                        click() {
                                            getPointById({ id: w.row.researchPointId }).then(res => {
                                                Object.assign(_this.modal, { show: true, data: {
                                                        ...res.data,
                                                        pointAdd: res.data.pointAdd && res.data.pointAdd.join(","),
                                                        published: res.data.published == true ? 1 : (res.data.published == false ? 0 : null),
                                                        point360: w.row.point360 / 1
                                                    } });
                                            });
                                            // Object.assign(_this.modal, { show: true, data: util.deepcopy({ ...w.row, pointAdd: w.row.pointAdd && w.row.pointAdd.join(",") }) });
                                        }
                                    }
                                }, "编辑"),
                                h("Button", {
                                    props: { type: "error", size: "small" },
                                    on: {
                                        click() {
                                            _this.$Modal.confirm({
                                                title: "删除",
                                                content: `是否删除当前${w.row.pointName}?`,
                                                onOk: () => {
                                                    deletePoint({ research_point_id: w.row.researchPointId }).then((res) => {
                                                        _this.successResult(res, "删除成功！")
                                                    });
                                                }
                                            });
                                        }
                                    }
                                }, "删除")
                            )
                        }
                        return btns
                    }
                },
            ],
        };
    },
    computed: {
        ...mapState({
            userId: state => state.user.userId,
            roleCode: state => state.user.roleCode
        })
    },
    methods: {

        // 打开课程
        openCourse(item) {
            console.log(item)
            if(item) {
                let id = item.split('/')[item.split('/').length - 1]
                Object.assign(this.course, { show: true, data: { fileName: '研学课程', id } })
            }
        },
        getPanorama(id) {
            getPanoramaById({ id }).then(({ code, data }) => {
                if (code == "HA0200") {
                this.panorama = data
                }
            })
        },
        // 打开弹框
        showModal() {
            const organizationName = this.$store.state.user.organization.organizationName;
            this.$refs["form"].resetFields();
            Object.assign(this.modal, { show: true, data: { pointCoord: { ele: 500 }, pointOrg: { base: organizationName }, published: 1 } });
        },
        // 保存数据
        saveData() {
            this.$refs["form"].validate((valid) => {
                if (valid) {
                    const { data } = this.modal;
                    let params = { ...data, pointAdd: data.pointAdd && data.pointAdd.split(",") };
                    util.setNullParams(params)
                    if (data.researchPointId) {
                        // 修改API
                        updatePoint(params).then(res => {
                            this.successResult(res, "编辑成功!", this.param.current);
                        });
                    }
                    else {
                        // 新增API
                        addPoint(params).then(res => {
                            this.successResult(res, "添加成功!");
                        });
                    }
                }
            });
        },
        // 新增修改成功回调
        successResult(res, descMsg, page) {
            if (res.code == "HA0200") {
                this.$Message.success(descMsg || res.msg);
                this.getData(page);
            }
            else {
                this.$Message.error(res.msg);
            }
        },
        // 获取数据列表
        getData(page) {
            Object.assign(this.param, { current: page ? page : 1 });
            getPoint({ userId: this.$store.state.user.userId, ...this.param }).then(res => {
                res.code == "HA0200" ? this.table = res : this.$Message.error(res.msg);
            });
        }
    },
    mounted() {
        this.isAuth = this.roleCode.includes('admin') || this.roleCode.includes('mechanismAdmin') || this.roleCode.includes('teach') || this.roleCode.includes('yxpt-t')
        this.getData();
    }
}
  </script>
  
<style lang="less" scoped>
    @media (min-width: 1024px) {
        .layout-config {
            
        }

    }
</style>
  